import {auth, GoogleProvider, db} from '../firebase.js';
import {useState} from 'react';
import { doc, getDoc, collection, query, where, getFirestore } from "firebase/firestore";
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';

export const Passcode = ({onPasscodeCorrect}) => {
    const [password, setPassword] = useState('');
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

    const passwordRef = collection(db, "Passwords");
    const q = query(passwordRef, where("Code", "==", true));

    const getDocuments = async (e) => {
        e.preventDefault()
        try {
                const docRef = doc(db, "Passwords", "Password");
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    if (password === docSnap.data().Password) {
                        console.log('success');
                        setIsPasswordCorrect(true);
                        onPasscodeCorrect();
                    } else {
                        console.log('Password is incorrect');
                        window.alert('WRONG PASSCODE ASSHOLE, NEED A HINT?')
                    }
                } else {
                    console.log('no such document')
                }
        } catch (err) {
            console.error(err);
        }
    }

    const showHint = () => {
        window.alert('All you seek is on this page. Only the worthy advance to what awaits. Struggling? Share a screenshot on your story, tag us, and provide our link. Our agents may offer guidance. Information should never be free. IG @xyberdreamss - P.S. NOT OPTIMISED FOR MOBILE, USE PC FOR FULL EXPERIENCE')
    }



    return (
        <>

            <div className='rect29'>
            </div>
            <input className='invis-input-passcode' type='password' onChange={(e) => setPassword(e.target.value)}></input>
            <button 
                className='invis-enter-button-passcode'
                onClick={getDocuments}
                />
            <button 
                className='show-hint-button'
                onClick={showHint}
                >Hint: $$$ Awaits</button>         
        </>
    )
}


{/* <form onSubmit={getDocuments}>
<input className='invis-input-passcode' type='password' onChange={(e) => setPassword(e.target.value)}></input>
<button 
    className='invis-enter-button-passcode'
    type='submit'
    /> */}