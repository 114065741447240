
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const firebaseConfig = {
  apiKey: "AIzaSyCvZZNQUj8fa5jKhQjpEo5oef5rJsrypQ0",
  authDomain: "xyberdreams-397c6.firebaseapp.com",
  projectId: "xyberdreams-397c6",
  storageBucket: "xyberdreams-397c6.appspot.com",
  messagingSenderId: "405945838751",
  appId: "1:405945838751:web:d1bbafe3bd7c40f61706aa",
  measurementId: "G-DHKQ0J06D5"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const GoogleProvider = new GoogleAuthProvider();

const db = getFirestore(app);
export {db};