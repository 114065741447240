import React, { useEffect, useState } from 'react';
import useSound from 'use-sound';
import maplestorymusic from './maplestorymusicTrimmed.mp3';

export const MainPopup = ({closeMainPopup}) => {
    const [isSoundEnabled, setSoundEnabled] = useState(false);
    const [play, {stop} ] = useSound(maplestorymusic);
    const [showMainPopupPage, setShowMainPopupPage] = useState(true);

    useEffect  (() => {
        if (isSoundEnabled) {
            play();
        } else {
            stop();
        }
        return () => {
            stop();
        }
    }, [isSoundEnabled]);

    const toggleSound = () => {
        setSoundEnabled(!isSoundEnabled);

    };

    const handleYesClick = async (e) => {
        e.preventDefault()
        if (isSoundEnabled) {
            await stop();
        } else {
            play();
            setShowMainPopupPage(false);
        }

        toggleSound();
    };

    


    return (
        <>
            <header className="header">
                <div
                    className={`sound-button ${isSoundEnabled ? 'sound-button' : 'sound-button-off'}`}
                    onClick={toggleSound}
                />
            </header>
            {showMainPopupPage && (
                <main className='main'>
                    <div className="overlay-transparent"></div>
                    <div className='rect25'>
                        <button 
                            className='invis-yes-button-main'
                            onClick={handleYesClick}
                            />
                        <button className='invis-close-button-main'
                                onClick={handleYesClick}
                            />
                    </div>
                </main>
            )}


        </>
    )
}