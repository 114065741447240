import {auth, db} from '../firebase.js';
import {signInWithEmailAndPassword } from 'firebase/auth'
import {useEffect, useState} from 'react';
import { doc, getDocs, collection, query, where, getFirestore } from "firebase/firestore";
import questwhite from '../photos/questwhite.webp';

export const Jobs = (props) => {
    const [jobsList, setJobsList] = useState([]); 
    const JobsCollectionRef = collection(db, 'Jobs');
    const [selectedJob, setSelectedJob] = useState(null);
    const [mainJobPage, setMainJobPage] = useState(true);

 
    useEffect(() => {
        const getJobs = async () => {
            
            try {
                const data = await getDocs(JobsCollectionRef);
                const filteredData = data.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                console.log(filteredData);
                setJobsList(filteredData);
            } catch (err) {
                console.error(err);
            }
        };

        getJobs();

    }, []);

    const handleClose = () => {
        props.closeJobPopup();
    }

    const openSubmissionPopup = () => {
        props.openSubmissionPopup();
    }

    const handleRowClick = (job) => {
      setSelectedJob(job);
      setMainJobPage(false);
      console.log('row Clicked', job)
    }

    const handleBackButton = () => {
      setMainJobPage(true);
      setSelectedJob(null);
    }



    return (
      <>
        {/* <div className='rect21'>
          <button className='invis-quest-close-button-red' onClick={handleClose}/>
          <div className='job-container'>
            <table className='table'>
              <thead>
                <tr className='minecraft-font-job'>
                  <th>Quest</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody className='job-table-font'>
                {jobsList.map((job) => (
                  <tr key={job.id} onClick={() => handleRowClick(job)}>
                    <td>${job.Price}</td>
                    <td>{job.Description}</td>
                    <td>{job.Availability}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}

        <div className='rect21'>
          <button className='invis-quest-close-button-red' onClick={handleClose}/>
          {mainJobPage && (
            <div className='job-container'>
              <table className='table'>
                <thead>
                  <tr className='quest-title-font'>
                    <th>Reward</th>
                    <th>Quest</th>
                    {/* <th>Availability</th> */}
                  </tr>
                </thead>
                <tbody>
                  {jobsList.map((job) => (
                    <tr key={job.id} onClick={() => handleRowClick(job)} className='job-table-font'>
                      <td>${job.Price}</td>
                      <td>{job.Description}</td>
                      {/* <td>{job.Availability}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {selectedJob && (
            <div className='job-container-selected'>
              <h2 className='minecraft-font-job'>Quest: {selectedJob.Description}</h2>
              <p className='minecraft-font-job'>Reward: ${selectedJob.Price}</p>
              {/* <p className='minecraft-font-job'>Extended Description: {selectedJob.ExtendedDescription}</p> */}
              {selectedJob.Description === 'VIEW$ 4 DOLLAR$' && 
              <>
                <p className='minecraft-font-job'>-Theme: CULT CURIOSITY </p>
                <p className='minecraft-font-job'>-Challenge: SHARE A PIC/VID ON ANY OF YOUR SOCIAL MEDIA THAT BUILDS CURIOSITY ABOUT THISISNOTACULT CULT. UNLEASH YOUR CREATIVITY.  </p>
                <p className='minecraft-font-job'>-Cash Reward = (NUMBER OF VIEWS / 4), e.g., 2000 views = $500</p>
                <p className='minecraft-font-job'>-Note: YOU MUST INCLUDE THE LINK TO OUR WEBSITE TO BE ELIGIBLE FOR THIS CHALLENGE: THISISNOTACULT.BIZ</p>
                <p className='minecraft-font-job'>-SUBMIT SCREENSHOT OF PROOF AND SEND TO OUR IG:XYBERDREAMSS</p>
                <p className='minecraft-font-job'>- ONE ATTEMPT PER CHALLENGE</p>
                <p className='minecraft-font-job'>- WE ROLL ON A FIRST COME FIRST SERVE BASIS, IF YOU SNOOZE, YOU LOSE. IF MISSED, KEEP YOUR EYES PEELED FOR OUR NEXT CHALLENGE BY SIGNING UP NEWSLETTER. </p>
              </>
              }
              {selectedJob.Description === 'Creative Challenge Alert' &&
              <>
                <p className='minecraft-font-job'>Do you possess that spark of virality? </p>
                <p className='minecraft-font-job'>Push the boundaries of your imagination and creativity! Impress us with your trailblazing content and stand a chance to be rewarded in ways you've never imagined. </p>
                <p className='minecraft-font-job'>A gentle reminder: Always stay within the limits of the law. We advocate creativity but not at the cost of legality, and we bear no responsibility for any unlawful actions. </p>
              </>
              }
              {selectedJob.Description === 'Raffle Opportunity!' &&
              <>
                <p className='minecraft-font-job'>Are you following us on Instagram, Reddit, and Twitter yet? </p>
                <p className='minecraft-font-job'>Dive into our social circles and stand a chance to win exclusive rewards every week! </p>
                <p className='minecraft-font-job'>Even if you missed the latest quest, your consistent loyalty might just land you a surprise. </p>
                <p className='minecraft-font-job'>Join our community and get rewarded.  </p>
                <p className='minecraft-font-job'>Instagram @xyberdreamss</p>
                <p className='minecraft-font-job'>Twitter @xyberdreams</p>
              </>
              }
              <button className="minecraft-font-mid" style={{marginRight: '5px'}}onClick={handleBackButton}>Back</button>
              <button className="minecraft-font-mid" style={{color: 'red'}} onClick={openSubmissionPopup}>Submit Quest</button>
            </div>
          )
          }

        </div>
      </>
      );
    };

    // <td><button className="job-table-font"onClick={openSubmissionPopup}>Submit Job</button></td>
    // <td><button className="job-table-font">???</button></td>