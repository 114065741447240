import React from "react";

export const Rectangle = ({ className, children, onClick }) => {
    return onClick ? (
      <div className={className} onClick={onClick}>
        {children}
      </div>
    ) : (
      <div className={className}>{children}</div>
    )
    
  };