import { auth, db } from '../firebase.js';
import { useEffect, useState } from 'react';
import { getDocs, collection, addDoc, serverTimestamp, orderBy, query, onSnapshot} from 'firebase/firestore';

export const Chatroom = (props) => {
    const [uniqueMessage, setUniqueMessage] = useState([]);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const messagesCollectionRef = collection(db, 'Messages');
    const queryTimestamp = query(messagesCollectionRef, orderBy('Timestamp', 'asc'));


    var nameList = [
      'Time','Past','Future','Dev',
      'Fly','Flying','Soar','Soaring','Power','Falling',
      'Fall','Jump','Cliff','Mountain','Rend','Red','Blue',
      'Green','Yellow','Gold','Demon','Demonic','Panda','Cat',
      'Kitty','Kitten','Zero','Memory','Trooper','XX','Bandit',
      'Fear','Light','Glow','Tread','Deep','Deeper','Deepest',
      'Mine','Your','Worst','Enemy','Hostile','Force','Video',
      'Game','Donkey','Mule','Colt','Cult','Cultist','Magnum',
      'Gun','Assault','Recon','Trap','Trapper','Redeem','Code',
      'Script','Writer','Near','Close','Open','Cube','Circle',
      'Geo','Genome','Germ','Spaz','Shot','Echo','Beta','Alpha',
      'Gamma','Omega','Seal','Squid','Money','Cash','Lord','King',
      'Duke','Rest','Fire','Flame','Morrow','Break','Breaker','Numb',
      'Ice','Cold','Rotten','Sick','Sickly','Janitor','Camel','Rooster',
      'Sand','Desert','Dessert','Hurdle','Racer','Eraser','Erase','Big',
      'Small','Short','Tall','Sith','Bounty','Hunter','Cracked','Broken',
      'Sad','Happy','Joy','Joyful','Crimson','Destiny','Deceit','Lies',
      'Lie','Honest','Destined','Bloxxer','Hawk','Eagle','Hawker','Walker',
      'Zombie','Sarge','Capt','Captain','Punch','One','Two','Uno','Slice',
      'Slash','Melt','Melted','Melting','Fell','Wolf','Hound',
      'Legacy','Sharp','Dead','Mew','Chuckle','Bubba','Bubble','Sandwich','Smasher','Extreme','Multi','Universe','Ultimate','Death','Ready','Monkey','Elevator','Wrench','Grease','Head','Theme','Grand','Cool','Kid','Boy','Girl','Vortex','Paradox'
    ]; 

    const addNewMessage = async (messageText, username) => {
        try {
          await addDoc(messagesCollectionRef, {
            Message: messageText,
            Username: username,
            Timestamp: serverTimestamp(),
          });
        } catch (err) {
          console.error(err);
        }
      };

      const listenForUpdates = () => {
        return onSnapshot(queryTimestamp, (querySnapshot) => {
          const newMessages = [];
          querySnapshot.forEach((doc) => {
            newMessages.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          setUniqueMessage(newMessages);
        });
      };

      useEffect(() => {
        // Start listening for real-time updates
        const unsubscribe = listenForUpdates();
    
        // Clean up the listener when the component unmounts
        return () => {
          unsubscribe();
        };
      }, []);

    const generateRandomUsername = () => {
      let finalName = '';
      finalName += nameList[Math.floor(Math.random() * nameList.length)];
      finalName += nameList[Math.floor(Math.random() * nameList.length)];
      if (Math.random() > 0.5) {
        finalName += nameList[Math.floor(Math.random() * nameList.length)];
      }
      return finalName;
    };

    const getUniqueMessage = async () => {
        try {
            const data = await getDocs(queryTimestamp);
            const filteredData = data.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setUniqueMessage(filteredData);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getUniqueMessage();
    }, []);

    const onSubmitThings = async (event) => {
        event.preventDefault();
        if (!message.trim()) {
            setErrorMessage('Please fill out the message field');
            return;
        }

        const randomUsername = generateRandomUsername();

        addNewMessage(message, randomUsername);
        setMessage('');
        setErrorMessage('Successfully Submitted');

        // try {
        //     await addDoc(messagesCollectionRef, {
        //         Message: message,
        //         Username: randomUsername,
        //         Timestamp: serverTimestamp(),
        //     });
        //     getUniqueMessage();
        //     setMessage('');
        //     setErrorMessage('Successfully Submitted');
        // } catch (err) {
        //     console.error(err);
        // }
    };

    const closeChatroom = () => {
        props.closeChatroom();
    };

    return (
        <>
            <div className='rect24'>
                    <div className="chatroom-message-container">
                        {uniqueMessage.map((message) => (
                            <div className="chatroom-message" key={message.id}>
                                <p>{message.Username}:</p>
                                <p className='chatroom-message-margin'>{message.Message}</p>
                            </div>
                        ))}
                    </div>
                    <button className='invis-close-chatroom-button' onClick={closeChatroom}></button>
                    <form onSubmit={onSubmitThings}>
                      <input
                        className="invis-input-chatroom"
                        placeholder='Message'
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        autoCapitalize='none'
                      ></input>
                      <button className="invis-submit-chatroom-button" type='submit'></button>
                    </form>
                    {errorMessage && <p className='error-message-chatroom'>{errorMessage}</p>}
            </div>
        </>
    );
};
