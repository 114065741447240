import React from 'react';
import styles from './Loading.module.css';

export const Loading = () => {
  return (
    <div className={styles.loadingAnimationContainer}>
        
        <div className={styles.loadingAnimation}>
          NOW LOADING
          <span className={styles.dot}>.</span>
            <span className={styles.dot}>.</span>
            <span className={styles.dot}>.</span>
          <br/>
          XYBERDREAMS <span style={{color:'red'}}>EXPERIENCE #1</span>
          <br/>
          THIS IS NOT A CULT CULT
        </div>
    </div>
  );
};
