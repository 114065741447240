import './App.css';
import React, {Suspense} from 'react';
import {auth, db} from './firebase.js';
import {SignIn} from './components/SignIn.jsx';
import {SignUp} from './components/SignUp.jsx';
import { useEffect, useState } from 'react';
import {getDocs, collection, addDoc} from 'firebase/firestore';
import 'intl-tel-input/build/css/intlTelInput.css';
import {AuthDetails} from './components/AuthDetails.jsx';
import {Passcode} from './components/Passcode.jsx';
import {Jobs} from './components/Jobs.jsx';
import {Submission} from './components/Submission.jsx';
import './styles.scss';
import {Chatroom} from './components/Chatroom.jsx';
import {Newsletter} from './components/Newsletter.jsx';
import { LoadingPopup } from './components/LoadingPopup';
import {Rectangle} from './components/Rectangle.jsx';
import {SoundButton} from './components/SoundOnOff.jsx';
import { Decree } from './components/Decree.jsx';
import { MainPopup } from './components/MainPopup.jsx';
import { Loading }from './components/Loading.jsx';

function App() {
  
  const [jobPopup, setJobPopup] = useState(false);
  const [imageJobPopup, setImageJobPopup] = useState(false);
  const [submissionPopup, setSubmissionPopup] = useState(false);
  const [imageSubmissionPopup, setImageSubmissionPopup] = useState(false);
  const [passcodeCorrect, setPasscodeCorrect] = useState(false);
  const [newsletterPopup, setNewsletterPopup] = useState(false);
  const [imageNewsletterPopup, setImageNewsletterPopup] = useState(false);
  const [chatroomPopup, setChatroomPopup] = useState(false);
  const [imageChatroomPopup, setImageChatroomPopup] = useState(false);
  const [isLoadingMusicPlaying, setIsLoadingMusicPlaying] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(true);
  const [byeLoadingPopup, setByeLoadingPopup] = useState(true);
  const [displayLoadingSoundOnOff, setDisplayLoadingSoundOnOff] = useState(false);
  const [passcodePopup, setPasscodePopup] = useState(false);
  const [mainPage, setMainPage] = useState(false);
  const [rect5, setRect5Visible] = useState(true);
  const [rect3, setRect3Visible] = useState(true);
  const [rect12, setRect12Visible] = useState(true);
  const [imageDecreePopup, setImageDecreePopup] = useState(false);
  const [imageMainPagePopup, setImageMainPagePopup] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  


  const handlePasscodeCorrect = () => {
    setPasscodeCorrect(true);
    setIsLoadingMusicPlaying(false);
    setMainPage(true);
    setDisplayLoadingSoundOnOff(false);
    setPasscodePopup(false);
    setByeLoadingPopup(false);
  };

  const handleOnEnter = () => {
    setLoadingPopup(false);
    setDisplayLoadingSoundOnOff(true)
    setIsLoadingMusicPlaying(false);
    setPasscodePopup(true);

  };

  const handleOnMainEnter = () => {

  }
  const handlePasscodePopup = () => {
    setPasscodePopup(true);
  };

  useEffect(() => {
    if (mainPage) {
      document.body.classList.add('body-style')
      document.body.classList.remove('black-body-style')
    }
    else {
      document.body.classList.add('black-body-style')
    }
  }, [mainPage])

  const handleRect3Click = () => {
    setChatroomPopup(prevState => !prevState);
    setImageChatroomPopup(prevState => !prevState);
    setImageJobPopup(false);
    setJobPopup(false);
    setImageDecreePopup(false);
    setSubmissionPopup(false);
    setImageSubmissionPopup(false);
  };

  const handleRect5Click = () => {
    setJobPopup(prevState => !prevState);
    setImageJobPopup(prevState => !prevState);
    setImageChatroomPopup(false);
    setChatroomPopup(false);
    setImageDecreePopup(false);
    setSubmissionPopup(false);
    setImageSubmissionPopup(false);
  };

  const handleRect12Click = () => {
    setNewsletterPopup(prevState => !prevState);
    setImageNewsletterPopup(true);
    setImageChatroomPopup(false);
    setChatroomPopup(false);
    setSubmissionPopup(false);
    setImageJobPopup(false);
    setJobPopup(false);
    setImageDecreePopup(false);
    setImageSubmissionPopup(false); 
  };

  const handleRect1Click = () => {
    setImageDecreePopup(prevState => !prevState);
    setImageChatroomPopup(false);
    setChatroomPopup(false);
    setSubmissionPopup(false);
    setImageJobPopup(false);
    setJobPopup(false);
    setImageSubmissionPopup(false);
  };

  const closeSubmissionPopup = () => {
    setSubmissionPopup(false);
    setImageSubmissionPopup(false);
  }

  const openSubmissionPopup = () => {
    setSubmissionPopup(true);
    setImageSubmissionPopup(true);
    setImageJobPopup(false);
    setJobPopup(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false)
    }
    , 3000)
    return () => clearTimeout(timer)
  }, [])
  
  if (showLoading) {
    return <Loading/>
  }


  return (
    <>  
        <div className='the-passcode-is-kronos-wasnt-that-hard-was-it?'>

        </div>
        <div className="app">
            {byeLoadingPopup &&(
              <LoadingPopup 
                className="rect26" 
                onEnter={handleOnEnter}
                isLoadingMusicPlaying={isLoadingMusicPlaying}
                setIsLoadingMusicPlaying={setIsLoadingMusicPlaying} />
            )}
          
  
          {!loadingPopup && passcodePopup && (
            <main className="main">
              <Passcode onPasscodeCorrect={handlePasscodeCorrect} />
            </main>
          )}
  
          {!loadingPopup && !passcodeCorrect && (
            <>
              <main className="main">
                <Passcode onPasscodeCorrect={handlePasscodeCorrect} />
              </main>
              <header className="header">
              </header>
            </>
          )}
  
    
  
          {!loadingPopup && passcodeCorrect && (
            <>
              <header className="header">
                {rect12 && (
                  <Rectangle
                    className="rect12"
                    onClick={handleRect12Click}
                  />
                )}
                <Rectangle className="rect10" />
                <Rectangle className="rect13" />
                <Rectangle className="rect14" />
                <Rectangle className="rect15" />
              </header>
  
              {!imageMainPagePopup && 
                  <>       
                    <MainPopup 
                      closeMainPopup = {() => setImageMainPagePopup(true)}
                      onMainEnter = {handleOnMainEnter}/>
                  </>
                }
  
              <main className="main">
                <Rectangle 
                  className="rect1"
                  onClick={handleRect1Click} />
                {imageDecreePopup && <Decree closeDecree={() => setImageDecreePopup(false)}/>}
                <Rectangle className="rect2" />
                {rect3 && (
                  <Rectangle
                    className="rect3"
                    onClick={handleRect3Click}
                  />
                )}
                {imageChatroomPopup && <Chatroom closeChatroom={() => setImageChatroomPopup(false)} />}
  
                <Rectangle className="rect4" />
                {rect5 && (
                  <Rectangle
                    className="rect5"
                    onClick={handleRect5Click}
                  />
                )}
                {imageJobPopup && 
                <>
                    <Jobs closeJobPopup={() => setImageJobPopup(false)} openSubmissionPopup={openSubmissionPopup}/> 
                  </>}
                {imageNewsletterPopup && <Newsletter closeNewsletter={() => setImageNewsletterPopup(false)}/>}
                <Rectangle className="rect6" />
                <Rectangle className="rect7" />
                <Rectangle className="rect8" />
                <Rectangle className="rect9" />
                <Rectangle className="rect16" />
                <Rectangle className="rect17" />
                <Rectangle className="rect18" />
                <Rectangle className="rect19" />
                <Rectangle className="rect20" />
                <Rectangle className="rect30" />
                <Rectangle className="rect31" />
                <Rectangle className="rect32" />
                <Rectangle className="rect33" />
                {imageSubmissionPopup && 
                  <Submission closeSubmissionPopup={closeSubmissionPopup}/>}
              </main>
            </>
          )}
        </div>
    </>
  );
}
export default App;






        {/* {!passcodeCorrect ? (
          <div className='mid-container'>
            <Passcode onPasscodeCorrect = {handlePasscodeCorrect}/>
          </div>
        ) : (
          <>
            <button onClick={() => setChatroomPopup(true)}>Show Chatroom</button>
            <button onClick={() => setChatroomPopup(false)}>Close Chatroom</button>
            <button onClick={() => setNewsletterPopup(true)}>Show Newsletter</button>
            <button onClick={() => setNewsletterPopup(false)}>Close Newsletter</button>
            <button onClick={() => setJobPopup(true)}>Show Jobs</button>
            <button onClick={() => setJobPopup(false)}> Close Jobs</button>
            <button onClick={() => setSubmissionPopup(false)}> Close Submission</button>
            <div className='mid-container'>
              {jobPopup && 
                <Jobs 
                  openSubmissionPopup={openSubmissionPopup}
                />}
              {submissionPopup && <Submission/>}

              {newsletterPopup && <Newsletter />}
              {chatroomPopup && <Chatroom />}
            </div>
          
          </>
        )} */}


        {/* <SignIn />
        <SignUp />
        <AuthDetails /> */}
        {/* <Passcode /> */}
      