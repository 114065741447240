import {auth, db} from '../firebase.js';
import { useEffect, useState } from 'react';
import {getDocs, collection, addDoc} from 'firebase/firestore'
import validator from 'validator';

export const Newsletter = (props) => {
  
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successState, setSuccessState] = useState(false);

    const emailsCollectionRef = collection(db, 'Emails');


    const captureEmail = async (e) => {
        if (!email.trim()) {
            setErrorMessage('Please fill out all fields');
            return;
        }

        if (!validator.isEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            return;
          }

        try {
          await addDoc(emailsCollectionRef, {
            Email: email,
          });
          setEmail('');
          console.log('complete')
          setErrorMessage('Successfully Submitted');
          setSuccessState(true);
          props.closeNewsletter();
        } catch (err) {
          console.error(err);
        }
      }

      const handleClicks = async () => {
        captureEmail();
        // props.closeNewsletter();
      }
      //   let emailCaptured = false;
      //   try {
      //     await captureEmail();
      //     emailCaptured = true;
      //     console.log('donefirstbit')
      //   } catch (error) {
      //     console.error(error);
      //   }
      
      //   if (emailCaptured) {
      //     props.closeNewsletter();
      //     console.log('done')
      //   }
      // };



    return (
        <>
            <div className="rect22">
                    <input className="invis-newsletter-input-container minecraft-font-mid"placeholder='Email...' onChange={(e) => setEmail(e.target.value)} value={email}></input>
                    <button className="invis-newsletter-submit-button" onClick={handleClicks}></button>
                {errorMessage && <p className='error-message'>{errorMessage}</p>}

          </div>

        </>
    );

}