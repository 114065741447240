import {auth, db} from '../firebase.js';
import {signInWithEmailAndPassword } from 'firebase/auth'
import {useEffect, useState} from 'react';
import { doc, getDocs, collection, query, where, getFirestore } from "firebase/firestore";

export const Decree = (props) => {

    const handleClicks = () => {
        props.closeDecree();
    }

    return (
      <>
        <div className='rect23'>
            <p className='decree-text'>With the unveiling of DROP 2: "This is not a cult Cult", XyberDreams is doing far more than introducing another product. This is the inception of a foundational layer, meticulously curated, to serve as fertile ground. It's here that we aim to nurture and grow a robust community, dedicated to transcending the ordinary. Our vision is not only about creating: it's about orchestrating narrative-centric, provocatively engaging masterpieces. Each is crafted with a clear objective: to ripple through conversations and spark virality.</p>
            <button className="invis-decree-close-big" onClick={handleClicks}></button>
            <button className="invis-decree-close-small" onClick={handleClicks}></button>
        </div>
      </>
      );
    };