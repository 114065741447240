import React, { useEffect, useState } from 'react';
import useSound from 'use-sound';
import hackerMusic from './hackermusicTrimmedFurther.mp3';

export const LoadingPopup = (props) => {


    const [isSoundEnabled, setSoundEnabled] = useState(false);
    const [play, {stop} ] = useSound(hackerMusic, {loop: true});
    const [show18Page, setShow18Page] = useState(true);
    const [yesClickSound, setYesClickSound] = useState(false);

    const toggleSound = () => {
        if (!yesClickSound) {
                setSoundEnabled(true);
        }
        else {
            setSoundEnabled(prevState => !prevState);
        }

    };

    const handleYesClick = () => {
        setYesClickSound(true);
        toggleSound();
        props.onEnter();
        setShow18Page(false);
    };


    useEffect(() => {
        if (isSoundEnabled) {
            play();
        } else {
            stop();
        }
        return () => {
            stop();
        }   
    }, [isSoundEnabled, play]);





    return (
        <>
            <header className="header">
                <div className={isSoundEnabled ? 'rect27' : 'rect28'} onClick={toggleSound}></div>
            </header>
            {show18Page && (
                <main className='main'>
                    <div className='rect26'>
                        <button 
                            className='invis-yes-button-loading'
                            onClick={handleYesClick}
                            />
                        <button 
                            className='invis-no-button-loading'
                            onClick={() => window.location.href = 'https://www.youtube.com/watch?v=ZZ5LpwO-An4&ab_channel=ProtoOfSnagem'}
                            />
                        <button 
                            className='invis-close-button-loading'
                            onClick={handleYesClick}
                            />
                            </div>
                        </main>
            )}
        

        </>
    )
}