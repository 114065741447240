import React, {useState, useEffect} from "react";
import {db} from '../firebase.js';
import {getDocs, collection, addDoc} from 'firebase/firestore'
import validator from "validator";

export const Submission = (props) => {

    const [email, setEmail] = useState('');
    const [payment, setPayment] = useState('');
    const [submissionLink, setSubmissionLink] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const jobsCollectionRef = collection(db, 'Submissions');

    const submitJob = async () => {

        if (!email.trim() || !payment.trim() || !submissionLink.trim()) {
            setErrorMessage('Please fill out all fields');
            return;
        }

        if (!validator.isEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            return;
        }

        if (!validator.isURL(submissionLink)) {
            setErrorMessage('Please enter a valid website URL');
            return;
          }
      

        try {
            await addDoc(jobsCollectionRef, {
                Email: email,
                Payment: payment,
                Link: submissionLink,
            });
            setEmail('');
            setPayment('');
            setSubmissionLink('');
            setErrorMessage('Successfully Submitted');
            console.log('job submitted');
        } catch (err) {
            console.error(err);
        }
    }

    const handleClose = () => {
        props.closeSubmissionPopup();
    }

    const handleBackButton = () => {
        props.closeSubmissionPopup();
    }

    return (
        <div className='rect21'>
            <button className='invis-quest-close-button-red' onClick={handleClose}/>
            {/* <div className="submission-container">
                <h1 className="minecraft-font-large" style={{color:'black'}}>Submission</h1>
                <div className="submission-input-container">
                    <input className="minecraft-font-mid" placeholder='Enter Your Email...' onChange={(e) => setEmail(e.target.value)} value={email}></input>
                    <input className="minecraft-font-mid" placeholder='Payment Information' onChange={(e)=> setPayment(e.target.value)} value={payment}></input>
                    <input className="minecraft-font-mid" placeholder='Submission Link' onChange={(e) => setSubmissionLink(e.target.value)} value={submissionLink}></input>
                    <button className="minecraft-font-mid" style={{color: 'red'}} onClick={submitJob}>Submit</button>
                    <button className="minecraft-font-mid" onClick={handleBackButton}>Back</button>

                    
                    {errorMessage && <p className=' minecraft-font-mid error-message-submission'>{errorMessage}</p>}
                </div>
            </div> */}
            <div className='job-container-selected'>
              <h2 className='minecraft-font-job'>PAYMENT METHOD</h2>
              <p className='minecraft-font-job'>- ONCE YOU HAVE DM US ON IG WITH PROOF, WE WILL GET BACK TO YOU ON IG FOR YOUR CHOICE OF PAYMENT. </p>
              <p className='minecraft-font-job'>- WE ROLL OUT PAYMENTS EVERY FRIDAY OF THE WEEK. </p>
              <p className='minecraft-font-job'>- FEEL FREE TO EMAIL US OR DM US ON SOCIALS IF YOU HAVE ANY QUESTIONS. </p>
              <p className='minecraft-font-job'>EMAIL: XYBERDREAMS@GMAIL.COM</p>
              <p className='minecraft-font-job'>INSTAGRAM: XYBERDREAMSS</p>
              <button className="minecraft-font-mid" style={{marginRight: '5px'}}onClick={handleBackButton}>Back</button>
              {/* <button className="minecraft-font-mid" style={{color: 'red'}} onClick={openSubmissionPopup}>Submit Quest</button> */}
            </div>
        </div>
    );
    };